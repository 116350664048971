import { createTheme } from "@mui/material/styles";
import { colors } from "./Colors";

//MUI CUSTOM THEME
export const theme = createTheme({
  palette: {
    primary: {
      main: colors.pink,
      light: colors.blue,
      dark: colors.black,
      white: "#FFFFF",
    },
  },

  typography: {
    fontFamily: ["Poppins", "Inter"].join(","),
  },

  components: {
    //checkbox
    MuiCheckbox: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "2rem",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& path": {
            color: colors.blue,
          },
          color: colors.blue,
        },
      },
    },

    //drawer
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: `${colors.blue_night}`,
        },
      },
    },

    //navbar
    AppBar: {
      variants: [
        {
          props: {
            variant: "mj_navbar",
          },
          style: {
            background: `${colors.blue_black}`,
            color: "white",
          },
        },
      ],
    },

    //Input textfield
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          backgroundColor: "white",
        },
      },
    },

    //Textfield
    MuiTextField: {
      styleOverrides: {
        root: {
          color: colors.blue_night,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.blue,
            },
            "&:hover fieldset": {
              border: `3px solid #E7D3FF`,
            },
            "&:focus fieldset": {
              border: `2px solid ${colors.pink}`,
            },
            "&.Mui-focused fieldset": {
              border: `2px solid ${colors.pink}`,
            },

            "& .MuiInputBase-input ": {
              padding: "10px 10px",
            },
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "title-page",
          },
          style: {
            color: `${colors.blue_black}`,
            fontWeight: "600",
            fontSize: "1.2rem",
          },
        },
      ],
    },
    MuiButton: {
      //BUTTONS VARIANTS
      variants: [
        //MJ_BUTTON_PRIMARY
        {
          props: { variant: "primary" },
          style: {
            fontWeight: "500",

            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: colors.blue,
            borderRadius: "2rem",
            color: "white",
            fontSize: "1rem",
            border: `2px solid ${colors.blue}`,
            "&:hover": {
              backgroundColor: colors.blue_night,
              color: "white",
              border: `2px solid ${colors.blue_night}`,
            },
            textTransform: "none",
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            fontWeight: "500",

            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: "red",
            borderRadius: "2rem",
            color: "white",
            fontSize: "1rem",
            border: `2px solid red`,
            "&:hover": {
              backgroundColor: `${colors.blue_night}`,
              color: "white",
              border: `2px solid ${colors.blue_night}`,
            },
            textTransform: "none",
          },
        },
        {
          props: { variant: "black" },
          style: {
            fontWeight: "500",

            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: "black",
            borderRadius: "2rem",
            color: "white",
            fontSize: "1rem",
            border: `2px solid black`,
            "&:hover": {
              backgroundColor: `${colors.blue_night}`,
              color: "white",
              border: `2px solid ${colors.blue_night}`,
            },
            textTransform: "none",
          },
        },
        {
          props: { variant: "third" },
          style: {
            fontWeight: "300",

            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: colors.black,
            borderRadius: "2rem",
            color: "white",
            fontSize: "0.875rem",
            border: `2px solid ${colors.black}`,
            "&:hover": {
              backgroundColor: colors.blue_night,
              color: "white",
              border: `2px solid ${colors.blue_night}`,
            },
            textTransform: "none",
          },
        },
      ],
    },
  },
});
