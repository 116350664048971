import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import { colors } from "../../theme/Colors";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import digitup from "../../imgs/digitup-logo.svg";
import { useLogin } from "../../hooks/useLogin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertError from "../../components/ui/AlertError";
import AlertSuccess from "../../components/ui/AlertSuccess";
import MzErrorText from "../../components/ui/MzErrorText";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
function ResetPass() {
  const [errorLogin, setErroNotFound] = useState();
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    Email: yup.string().email("entrer un email valide").required("ce champ est obligatoire"),
  });
  const formik = useFormik({
    initialValues: {
      Email: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      onSubmitLogin();
    },
  });
  const onSubmitLogin = () => {
    setIsLoading(true);
    setErroNotFound();
    setSuccess();
    Api.post(
      Urls.PASSWORD_RESET,
      {
        email: formik.values.Email,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setSuccess(`Votre nouveau mot de passe a été envoyé par email. `);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setErroNotFound("Utilisateur introuvable");
        } else {
          setErroNotFound(err.response.data.message);
        }
        setIsLoading(false);
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
      });
  };
  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: `${colors.blue_night}` }} display="flex" alignItems="center" justifyContent="center">
      <Box sx={{ boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.65)", padding: "2rem", background: "white", borderRadius: "5px", display: "flex", flexDirection: "column", gap: "0rem" }}>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
          <img src={digitup} alt="logo" />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box margin="1rem 2rem" display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <label>Email : </label>
              <TextField type="email" name="Email" value={formik.values.Email} onChange={formik.handleChange} placeholder="Email" sx={{ width: { xs: "180px", lg: "300px", md: "300px", sm: "250px" } }} />
              {formik.errors.Email && formik.touched.Email && <MzErrorText>{formik.errors.Email}</MzErrorText>}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button type="submit" disabled={isLoading} variant="primary">
              Récupérer mot de passe
            </Button>
          </Box>
        </form>
        {errorLogin && <AlertError>{errorLogin}</AlertError>}
        {success && (
          <AlertSuccess sx={{ cursor: "pointer" }}>
            {success}{" "}
            <Typography onClick={() => navigate("/login")} sx={{ textDecoration: "underline", cursor: "pointer" }} fontSize="0.875rem" display="inline">
              Retournez vers Authentification
            </Typography>
          </AlertSuccess>
        )}
      </Box>
    </Box>
  );
}

export default ResetPass;
