import React, { useEffect, useRef, useMemo, useState } from "react";
import { Box, Typography, Button, TextField, Autocomplete, Avatar, IconButton, Modal, FormControlLabel, Checkbox, Alert } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { modalStyle } from "../../theme/ModalStyle";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { colors } from "../../theme/Colors";
import MzErrorText from "../../components/ui/MzErrorText";
import JoditEditor from "jodit-react";
import * as yup from "yup";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";

function DetailsArticle() {
  const formRef = useRef();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [image, setImage] = useState(null);

  //jodit editor params
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [contentENG, setContentENG] = useState("");
  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];
  //validation schema article
  const validationSchema = yup.object().shape({
    nom_fr: yup.string().required("ce champ est obligatoire"),
    nom_eng: yup.string().required("ce champ est obligatoire"),
    text_fr: yup.string().required("ce champ est obligatoire"),
    text_eng: yup.string().required("ce champ est obligatoire"),
    tags: yup.array().min(1, "entrer au moin un tag"),
  });
  const validationSchemaPic = yup.object().shape({
    image: yup
      .mixed()
      .required("Veuillez télécharger un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });
  //modal states
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  //state succes error
  const [succes, setSucces] = useState("");
  const [error, setError] = useState("");
  //get tags
  const [tag, setTag] = useState([]);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_TAGS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setTag(res.data.tags);
        let array = [];
        for (let index = 0; index < res.data.tags.length; index++) {
          const element = res.data.tags[index];
          array.push(element.name);
        }
        //console.log(array, "array tags");
        setOptions(array);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);
  // get article details
  const [change, setChange] = useState(false);
  useEffect(() => {
    Api.get(Urls.GET_ARTCIEL_BY_ID + id, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: { lang: "fr" },
    })
      .then((res) => {
        let array = [];
        //console.log(res);
        formRef.current.values.image = res?.data?.post?.image_url;
        setImage(res?.data?.post?.image_url);
        formRef.current.values.nom_fr = res?.data?.post?.title_fr;
        formRef.current.values.nom_eng = res?.data?.post?.title_en;
        formRef.current.values.text_eng = res?.data?.post?.text_en;
        formRef.current.values.text_fr = res?.data?.post?.text_fr;
        formRef.current.values.text_eng = res?.data?.post?.text_en;
        setContentENG(res?.data?.post?.text_en);
        setContent(res?.data?.post?.text_fr);
        formRef.current.values.Visible = res?.data?.post?.visible === 0 ? false : true;
        formRef.current.values.en_vedette = res?.data?.post?.en_vedette === 0 ? false : true;
        for (let index = 0; index < res?.data?.post?.tags?.length; index++) {
          const element = res?.data?.post?.tags[index];
          array.push(element.name_en);
        }
        formRef.current.values.tags = array;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [change]);
  //UPDATE PIC
  const updatePic = (values) => {
    //console.log(values.image);
    Api.post(
      Urls.UPDATE_PIC + id + "/image",
      {
        image: values.image,
      },
      {
        headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        setChange(!change);
        toast.success("Photo modifié avec succés");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //update article
  //create article
  const UpdateArticle = (values) => {
    //console.log(values);
    let array = [];
    for (let index = 0; index < values?.tags?.length; index++) {
      for (let i = 0; i < tag.length; i++) {
        const element = tag[index];
        if (values?.tags[index] === tag[i].name) {
          array.push(tag[i].id);
        }
      }
    }
    Api.patch(
      Urls.UPDATE_ARTICLE + id,
      {
        title_en: values.nom_eng,
        title_fr: values.nom_fr,
        text_en: values.text_eng,
        text_fr: values.text_fr,
        en_vedette: values.en_vedette ? 1 : 0,
        visible: values.Visible ? 1 : 0,
        tags: array,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        formRef.current.values = formRef.current.initialValues;
        window.scrollTo(0, 0);
        setChange(!change);
        setOpenEdit(false);
        toast.success("Article modifié avec succés");
      })
      .catch((error) => {
        //console.log(error);
        formRef.current.values = formRef.current.initialValues;
        window.scrollTo(0, 0);
        setChange(!change);
        setOpenEdit(false);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //delete article

  const deleteArticle = (id) => {
    Api.delete(Urls.DELETE_ARTICLE + id, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res);
        setChange(!change);
        setOpenDelete(false);
        navigate("/admin/gestion-des-articles");
      })
      .catch((error) => {
        //console.log(error);
        setOpenDelete(false);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  const configFr = useMemo(() => {
    return {
      height: "400px",
      language: "fr",
      maxWidth: "80%",
      readonly: false,
      placeholder: "Contenu de l'article en francais ...",
      //buttons: ["bold", "italic", "link", "unlink", "underline", "ul", "ol", "font", "fontSize", "paragraph", "hr", "print"],
    };
  }, []);
  const configeEn = useMemo(() => {
    return {
      height: "400px",
      language: "fr",
      maxWidth: "80%",
      readonly: false,
      placeholder: "Contenu de l'article en englais ...",
      //buttons: ["bold", "italic", "link", "unlink", "underline", "ul", "ol", "font", "fontSize", "paragraph", "hr", "print"],
    };
  }, []);
  return (
    <Box>
      <Box marginBottom="2rem">
        <Typography variant="title-page">Détails de l'article</Typography>
      </Box>
      {error && (
        <Alert sx={{ margin: "1rem 0rem" }} severity="error">
          {error}
        </Alert>
      )}
      {succes && (
        <Alert sx={{ margin: "1rem 0rem" }} severity="success">
          {succes}
        </Alert>
      )}
      <Box sx={{ marginBottom: "2rem" }}>
        <Box display="flex" flexDirection="column" gap="1rem">
          <Formik
            innerRef={formRef}
            initialValues={{
              image: "",
            }}
            validationSchema={validationSchemaPic}
            onSubmit={(values) => {
              updatePic(values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <Box display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Image de l'article</label>
                  <Avatar
                    sx={{
                      position: "relative",
                      borderRadius: "20px",
                      width: { xs: "300px", lg: "400px", md: "400px", sm: "300px" },
                      height: "226px",
                      border: "1px solid #C9C5D2",
                      marginBottom: "1rem",
                    }}
                    variant="rounded"
                  >
                    {image ? <img src={image} style={{ height: "100%", width: "100%" }} alt="photo article" /> : null}
                    <IconButton
                      sx={{
                        zIndex: "33",
                        position: "absolute",
                        right: "0.5rem",
                        bottom: "0.5rem",
                        bgcolor: `${colors.blue}`,
                        "&:hover": { bgcolor: `${colors.blue_night}` },
                      }}
                      component="label"
                    >
                      <input
                        hidden
                        id="image"
                        name="image"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0] ? event.target.files[0] : null);
                          setImage(URL.createObjectURL(event.target.files[0]));
                        }}
                      />
                      <AddRoundedIcon htmlColor="white" />
                    </IconButton>
                  </Avatar>
                  {errors.image && touched.image && <MzErrorText>{errors.image}</MzErrorText>}
                </Box>
                <Button type="submit" variant="primary">
                  Enregistrer
                </Button>
              </Form>
            )}
          </Formik>
          <Formik
            innerRef={formRef}
            initialValues={{
              nom_fr: "",
              nom_eng: "",
              en_vedette: false,
              Visible: false,
              text_fr: "",
              text_eng: "",
              tags: [],
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setOpenEdit(true);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <Box maxWidth="400px" display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Titre en francais :</label>
                  <Field type="input" name="nom_fr" as={TextField} label="Titre en francais" id="Nom" size="small" />
                  {errors.nom_fr && touched.nom_fr && <MzErrorText>{errors.nom_fr}</MzErrorText>}
                </Box>
                <Box maxWidth="400px" display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Titre en englais :</label>
                  <Field type="input" name="nom_eng" as={TextField} label="Titre en anglais" id="Nom" size="small" />
                  {errors.nom_eng && touched.nom_eng && <MzErrorText>{errors.nom_eng}</MzErrorText>}
                </Box>
                <Box maxWidth="400px" display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Tags en relation avec l'article : </label>
                  <Field
                    multiple={true}
                    name="tags"
                    component={Autocomplete}
                    options={options}
                    value={values.tags}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(s) => s}
                    onChange={(e, value) => {
                      setFieldValue("tags", value ? value : "");
                      //console.log(value);
                    }}
                    renderInput={(params) => <TextField sx={{ minWidth: "300px" }} {...params} name="autocomplete" label="Tags" />}
                    size="small"
                  />
                  {errors.tags && touched.tags && <MzErrorText>{errors.tags}</MzErrorText>}
                </Box>
                <Box display="flex" gap="0.5rem" alignItems="center">
                  <FormControlLabel control={<Checkbox checked={values.en_vedette} onChange={(e) => setFieldValue("en_vedette", e.target.checked)} />} label="En vedette" />
                  <FormControlLabel control={<Checkbox checked={values.Visible} onChange={(e) => setFieldValue("Visible", e.target.checked)} />} label="Visible" />
                </Box>
                <Box display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Contenu de l'article en francais :</label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={configFr}
                    tabIndex={1} // tabIndex of textarea
                    onChange={(newContent) => {
                      setFieldValue("text_fr", newContent);
                      setContent(newContent);
                    }} // preferred to use only this option to update the content for performance reasons
                  />
                  {errors.text_fr && <MzErrorText>{errors.text_fr}</MzErrorText>}
                </Box>
                <Box display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Contenu de l'article en englais :</label>
                  <JoditEditor
                    ref={editor}
                    value={contentENG}
                    config={configeEn}
                    tabIndex={1} // tabIndex of textarea
                    onChange={(newContent) => {
                      setFieldValue("text_eng", newContent);
                      setContentENG(newContent);
                    }} // preferred to use only this option to update the content for performance reasons
                  />
                  {errors.text_eng && <MzErrorText>{errors.text_eng}</MzErrorText>}
                </Box>
                <Box margin="2rem 0rem" display="flex" alignItems="center" gap="1rem">
                  <Button type="submimt" variant="primary">
                    Modifier l'article
                  </Button>
                  <Button onClick={() => setOpenDelete(true)} variant="black">
                    Supprimer l'article
                  </Button>
                </Box>
                <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
                  <Box sx={modalStyle}>
                    <Box marginBottom="2rem" display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: `${colors.blue}`, padding: "1rem", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
                      <Typography fontWeight="600" color={colors.black} textAlign="center">
                        Modifier un article
                      </Typography>
                    </Box>
                    <Box padding="0rem 2rem" marginBottom="1rem">
                      <Typography textAlign="center">Voulez vous vraiment modifier cet article ?</Typography>
                    </Box>
                    <Box margin="0rem 1rem 1rem 1rem" display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
                      <Button onClick={() => UpdateArticle(values)} variant="primary">
                        Confirmer
                      </Button>
                      <Button onClick={() => setOpenEdit(false)} variant="black">
                        Annuler
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </Form>
            )}
          </Formik>
          <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
            <Box sx={modalStyle}>
              <Box marginBottom="2rem" display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: `red`, padding: "1rem", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
                <Typography fontWeight="600" color={colors.black} textAlign="center">
                  Supprimer un article
                </Typography>
              </Box>
              <Box padding="0rem 2rem" marginBottom="1rem">
                <Typography textAlign="center">Voulez vous vraiment supprimer cet article ?</Typography>
              </Box>
              <Box margin="0rem 1rem 1rem 1rem" display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
                <Button onClick={() => deleteArticle(id)} variant="primary">
                  Confirmer
                </Button>
                <Button onClick={() => setOpenDelete(false)} variant="black">
                  Annuler
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </Box>
  );
}

export default DetailsArticle;
