export const Urls = {
  //auth
  LOGIN_URL: "/auth/login",

  //logout
  LOGOUT: "/auth/logout",

  //get list of tags
  GET_TAG_LIST: "/tags-with-filter",

  //create a tag
  CREATE_TAG: "/tags",

  //delete tag
  DELETE_TAG: "/tags/",

  // update tag
  UPDATE_TAG: "/tags/",

  //get articles
  GET_ARTICLES: "/posts-with-filter",

  //get tags for select
  GET_TAGS: "/tags",

  //creer un article
  CREATE_ARTICLE: "/posts",

  //delete article
  DELETE_ARTICLE: "/posts/",

  //get article by id

  GET_ARTCIEL_BY_ID: "/post-by-id/",

  //update article pic

  UPDATE_PIC: "/posts/",

  //update article
  UPDATE_ARTICLE: "/posts/",

  //update user password

  UPDATE_USER_PASSWORD: "/users/",

  //reset password
  PASSWORD_RESET: "/auth/reset-password",
};
