import React, { useState, useEffect, useRef } from "react";
import { Button, Box, Typography, TextField, Autocomplete, Modal } from "@mui/material";
import { Field, ErrorMessage, Formik, Form } from "formik";
import MzErrorText from "../../components/ui/MzErrorText";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import LinearProgress from "@mui/material/LinearProgress";
import { rows } from "../../data/ArticlesData";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { frFR } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "../../theme/Colors";
import { modalStyle } from "../../theme/ModalStyle";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Urls } from "../../api/Urls";
import Api from "../../api/Api";
import { useAuthContext } from "../../hooks/useAuthContext";
function Articles() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const formRef = useRef();

  //useEffect state change
  const [change, setChange] = useState(false);
  const [page, setPage] = useState(1);
  const pageNumbers = [2, 25, 50, 100];
  const [perPage, setPerPage] = useState(pageNumbers[1]);

  //loading datagrid
  const [loading, setIsLoading] = useState(true);
  //state modal
  const [openDELETE, setOpenDelete] = useState(false);
  //setting the datagrid columns
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      headerClassName: "grid-header",
    },
    {
      field: "title_fr",
      headerName: "Nom Fr",
      description: "Nom Fr",
      width: 150,
      align: "left",
    },
    {
      field: "title_en",
      headerName: "Nom Eng",
      description: "Nom Eng",
      width: 150,
      align: "left",
    },
    {
      field: "created_at",
      headerName: "Créé le",
      description: "date de création du tag",
      width: 150,
      renderCell: (params) => {
        return params.row.created_at?.split("T")[0];
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "Action sur la ligne.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
            <ReadMoreIcon onClick={() => navigate("/admin/gestion-des-articles/" + params.row.id)} sx={{ fontSize: 35, color: `${colors.blue}`, cursor: "pointer" }} />
            <DeleteIcon
              onClick={() => {
                setOpenDelete(true);
                setID(params.row.id);
              }}
              sx={{ color: "darkred", cursor: "pointer" }}
            />
          </Box>
        );
      },
      width: 150,
    },
  ];
  //setting tags
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [id, setID] = useState(null);
  const [row, setRow] = useState(null);
  // GETTING THE LIST OF TAGS
  useEffect(() => {
    Api.get(Urls.GET_ARTICLES + `?perPage=${perPage}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
      params: { perPage: perPage },
    })
      .then((res) => {
        //console.log(res);
        setRows(res.data.posts);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);
  //console.log(access_token);

  useEffect(() => {
    let tags = [];
    for (let index = 0; index < formRef.current.values?.tags?.length; index++) {
      for (let i = 0; i < tag.length; i++) {
        const element = tags[index];
        if (formRef.current.values?.tags[index] === tag[i].name) {
          tags.push(tag[i].id);
        }
      }
    }
    Api.get(Urls.GET_ARTICLES + `?perPage=${perPage}&page=${page + 1}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
      params: nonEmptyObject({ tags }),
    })
      .then((res) => {
        //console.log(res);
        setRows(res.data.posts);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [page, perPage, change]);
  //tags
  const [tag, setTag] = useState([]);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_TAGS + "?perPage=10", {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setTag(res.data.tags);
        let array = [];
        for (let index = 0; index < res.data.tags.length; index++) {
          const element = res.data.tags[index];
          array.push(element.name);
        }
        //console.log(array, "array tags");
        setOptions(array);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);
  //search
  //console.log(options);
  const onSubmitSearchposts = (values) => {
    let realValues = nonEmptyObject(values);
    // //console.log("VALUES", values);
    // //console.log("REAL VALUES", realValues);
    // //console.log("at from submit", access_token);
    let array = [];
    for (let index = 0; index < values?.tags?.length; index++) {
      for (let i = 0; i < tag.length; i++) {
        const element = tag[index];
        if (values?.tags[index] === tag[i].name) {
          array.push(tag[i].id);
        }
      }
    }
    setPage(0);
    setIsLoading(true);
    Api.get(Urls.GET_ARTICLES + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: {
        tags: array,
      },
    })
      .then((response) => {
        // //console.log("response", response);
        setIsLoading(false);
        setRows(response.data.posts);
        setData(response.data);
        //console.log(response);
      })
      .catch((error) => {
        // //console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //navigate
  const navigate = useNavigate();
  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }
  //delete article
  const deleteArticle = () => {
    Api.delete(Urls.DELETE_ARTICLE + id, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res);
        setChange(!change);
        setOpenDelete(false);
        toast.success("Article supprimé avec succés");
      })
      .catch((error) => {
        //console.log(error);
        setOpenDelete(false);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  return (
    <Box>
      <Box marginBottom="2rem">
        <Typography variant="title-page">Gestion des articles</Typography>
      </Box>
      <Box marginBottom="2rem">
        <Button onClick={() => navigate("/admin/creer-article")} variant="primary">
          Créer un Article
        </Button>
      </Box>
      <Box sx={{ marginBottom: "2rem" }}>
        <Formik
          innerRef={formRef}
          initialValues={{
            tags: [],
          }}
          onSubmit={(values) => {
            onSubmitSearchposts(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" alignItems="center" gap="1rem">
                <Typography sx={{ fontWeight: "600" }}>Filtres :</Typography>
                <Box>
                  <Field
                    multiple={true}
                    name="tags"
                    component={Autocomplete}
                    options={options}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(s) => s}
                    onChange={(e, value) => {
                      setFieldValue("tags", value ? value : "");
                      //console.log(value);
                    }}
                    renderInput={(params) => <TextField sx={{ minWidth: "300px" }} {...params} name="autocomplete" label="Tags" />}
                    size="small"
                  />
                  <ErrorMessage name="tags" as={MzErrorText} />
                </Box>
                <Box>
                  <Button type="submit" variant="primary">
                    Chercher
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          // sx={{ maxWidth: "1180px" }}
          sx={{ maxWidth: "665px" }}
          rowHeight={40}
          //allow for multiple rows selection when checkboxSelection is on

          // onSelectionModelChange={(ids) => {
          //   const selectedIDs = new Set(ids);
          //   const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
          //   setSelectedRows(selectedRows);
          // }}
          loading={loading}
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // //console.log(newPageSize);
          }}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          onPageChange={(newPage) => {
            // //console.log(newPage);
            setPage(newPage);
          }}
          page={page}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rows}
          //allow selection
          // checkboxSelection
          //how many rows in a page ?
          rowCount={data?.meta?.total}
          //mode
          paginationMode="server"
          //total number of rows per search
          //onpage change
          pagination
        />
        {/* <pre>{JSON.stringify(selectedRows, null, 2)}</pre>*/}
      </Box>

      <Modal open={openDELETE} onClose={() => setOpenDelete(false)}>
        <Box sx={modalStyle}>
          <Box marginBottom="2rem" display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: `red`, padding: "1rem", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
            <Typography fontWeight="600" color={colors.black} textAlign="center">
              Supprimer un article
            </Typography>
          </Box>
          <Box padding="0rem 2rem" marginBottom="1rem">
            <Typography textAlign="center">Voulez vous vraiment supprimer cet article ?</Typography>
          </Box>
          <Box margin="0rem 1rem 1rem 1rem" display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
            <Button onClick={() => deleteArticle()} variant="primary">
              Confirmer
            </Button>
            <Button onClick={() => setOpenDelete(false)} variant="black">
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Articles;
