import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useNavigate } from "react-router-dom";
import IconDigitup from "../../imgs/digitup-logo-white.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink } from "react-router-dom";
import StyleIcon from "../../imgs/tags.png";
import NewspaperIcon from "../../imgs/article.svg";
import PersonIcon from "../../imgs/user.svg";
import { colors } from "../../theme/Colors";
import SharedLayoutStyle from "./styles/SharedLayout.css";

const drawerWidth = 300;
const navigation = [
  { title: "Gestion des tags", path: "/admin/gestion-des-tags" },
  { title: "Gestion des articles", path: "/admin/gestion-des-articles" },
];

const icons = {};
icons["Gestion des tags"] = StyleIcon;
icons["Gestion des articles"] = NewspaperIcon;
function AdminSharedLayout(props) {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  // console.log("ACCESS_TOKEN", access_token);

  const imageSrc = null;

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();

  /* const handleLogout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT, null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        // console.log("Response Logout", response);
        if (response.status === 200) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error.response.data.message);
      });
  };*/

  const drawer = (
    <Box>
      <Toolbar sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <Box>
          <Box sx={{ padding: "2.5rem 0rem" }}>
            <Box>
              <img src={IconDigitup} width={250} alt="digitup logo" />
            </Box>
          </Box>
        </Box>
      </Toolbar>

      <List>
        {navigation.map((l, index) => (
          <ListItem key={index} disablePadding>
            <NavLink style={{ textDecoration: "none" }} id="navlink" className={({ isActive }) => (isActive ? "active" : "inactive")} to={l.path}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <ListItemIcon>
                  <img style={{ width: "20px", marginLeft: "20px" }} src={icons[`${l.title}`]} />
                </ListItemIcon>
                <ListItemText sx={{ color: "white" }} primary={l.title} />
              </Box>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  //logout

  const handleLogout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT, null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        // console.log("Response Logout", response);
        if (response.status === 200) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error.response.data.message);
      });
  };
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ backgroundColor: `${colors.blue}`, color: "white", display: "flex", justifyContent: "space-between" }}>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon></MenuIcon>
          </IconButton>
          <Box></Box>
          <Box display="flex" gap="1.5rem" justifyContent="center" alignItems="center">
            <Box textAlign="right">
              <Typography noWrap component="div">
                Administrateur
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ width: "3rem", height: "3rem", backgroundColor: "transparent" }}>{imageSrc === null ? <img style={{ width: "3rem" }} src={PersonIcon} alt="" /> : <img style={{ width: "3rem" }} id="imgNavbar" src={imageSrc} alt="" />}</Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <Typography onClick={() => handleLogout()} textAlign="center">
                    Se déconnecter
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography onClick={() => navigate("/admin/mon-compte")} textAlign="center">
                    Mon profile
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Box sx={{ padding: { md: "2rem", sm: "0.2rem" } }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

AdminSharedLayout.propTypes = {
  window: PropTypes.func,
};

export default AdminSharedLayout;
