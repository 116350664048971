import "./App.css";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "./pages/authentification/Login";
import Welcome from "./pages/admin/Welcome";
import AdminSharedLayout from "./pages/admin/AdminSharedLayout";
import Tags from "./pages/admin/Tags";
import Articles from "./pages/admin/Articles";
import CreateArticle from "./pages/admin/CreateArticle";
import DetailsArticle from "./pages/admin/DetailsArticle";
import { useAuthContext } from "./hooks/useAuthContext";
import MonCompte from "./pages/admin/MonCompte";
import ResetPass from "./pages/authentification/ResetPass";
function App() {
  const { user } = useAuthContext();
  return (
    <Routes>
      <Route path="" element={<Navigate to="login" />} />
      <Route path="mot-de-passe-oublier" element={user?.access_token ? <Navigate to="/admin" replace /> : <ResetPass />} />
      <Route path="/" element={<Outlet />} />
      <Route path="login" element={user ? <Navigate to="/admin" /> : <Login />} />
      <Route path="/admin" element={user ? <AdminSharedLayout /> : <>Unauthorized</>}>
        <Route index element={<Welcome />} />
        <Route path="/admin/gestion-des-tags" element={<Tags />} />
        <Route path="/admin/gestion-des-articles" element={<Articles />} />
        <Route path="/admin/creer-article" element={<CreateArticle />} />
        <Route path="/admin/gestion-des-articles/:id" element={<DetailsArticle />} />
        <Route path="/admin/mon-compte" element={<MonCompte />} />
      </Route>
    </Routes>
  );
}

export default App;
