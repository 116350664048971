import React, { useRef, useState, useEffect, useMemo } from "react";
import { Box, Typography, Button, TextField, Autocomplete, Avatar, IconButton, FormGroup, FormControlLabel, Checkbox, Alert } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { colors } from "../../theme/Colors";
import MzErrorText from "../../components/ui/MzErrorText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import * as yup from "yup";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useNavigate } from "react-router-dom";

function CreateArticle() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const formRef = useRef();
  const [image, setImage] = useState(null);

  //jodit editor params
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [contentENG, setContentENG] = useState("");
  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];
  //validation schema article
  const validationSchema = yup.object().shape({
    nom_fr: yup.string().required("ce champ est obligatoire"),
    nom_eng: yup.string().required("ce champ est obligatoire"),
    image: yup
      .mixed()
      .required("Veuillez télécharger un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
    text_fr: yup.string().required("ce champ est obligatoire"),
    text_eng: yup.string().required("ce champ est obligatoire"),
    tags: yup.array().min(1, "entrer au moin un tag"),
  });
  const [options, setOptions] = useState([]);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_TAGS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setTags(res.data.tags);
        let array = [];
        for (let index = 0; index < res.data.tags.length; index++) {
          const element = res.data.tags[index];
          array.push(element.name);
        }
        //console.log(array, "array tags");
        setOptions(array);
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);
  //state succes error
  const [succes, setSucces] = useState("");
  const [error, setError] = useState("");
  //create article
  const CreateArticle = (values) => {
    //console.log(values);
    let array = [];
    for (let index = 0; index < values?.tags?.length; index++) {
      for (let i = 0; i < tags.length; i++) {
        const element = tags[index];
        if (values?.tags[index] === tags[i].name) {
          array.push(tags[i].id);
        }
      }
    }
    Api.post(
      Urls.CREATE_ARTICLE,
      {
        title_en: values.nom_eng,
        title_fr: values.nom_fr,
        text_en: values.text_eng,
        text_fr: values.text_fr,
        image: values.image,
        en_vedette: values.en_vedette ? 1 : 0,
        visible: values.Visible ? 1 : 0,
        tags: array,
      },
      {
        headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success("Article crée avec succés");
        formRef.current.values = formRef.current.initialValues;
      })
      .catch((error) => {
        //console.log(error);
        formRef.current.values = formRef.current.initialValues;
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  const configFr = useMemo(() => {
    return {
      height: "400px",
      language: "fr",
      maxWidth: "80%",
      readonly: false,
      placeholder: "Contenu de l'article en francais ...",
      //buttons: ["bold", "italic", "link", "unlink", "underline", "ul", "ol", "font", "fontSize", "paragraph", "hr", "print"],
    };
  }, []);
  const configeEn = useMemo(() => {
    return {
      height: "400px",
      language: "fr",
      maxWidth: "80%",
      readonly: false,
      placeholder: "Contenu de l'article en englais ...",
      //buttons: ["bold", "italic", "link", "unlink", "underline", "ul", "ol", "font", "fontSize", "paragraph", "hr", "print"],
    };
  }, []);
  return (
    <Box>
      <Box marginBottom="2rem">
        <Typography variant="title-page">Créer un article</Typography>
      </Box>
      {error && (
        <Alert sx={{ margin: "1rem 0rem" }} severity="error">
          {error}
        </Alert>
      )}
      {succes && (
        <Alert sx={{ margin: "1rem 0rem" }} severity="success">
          {succes}
        </Alert>
      )}

      <Box sx={{ marginBottom: "2rem" }}>
        <Formik
          innerRef={formRef}
          initialValues={{
            nom_fr: "",
            nom_eng: "",
            en_vedette: false,
            Visible: false,
            image: "",
            text_fr: "",
            text_eng: "",
            tags: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            CreateArticle(values);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Box display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Image de l'article</label>
                  <Avatar
                    sx={{
                      position: "relative",
                      borderRadius: "20px",
                      width: { xs: "300px", lg: "400px", md: "400px", sm: "300px" },
                      height: "226px",
                      border: "1px solid #C9C5D2",
                      marginBottom: "1rem",
                    }}
                    variant="rounded"
                  >
                    {image ? <img src={image} style={{ height: "100%", width: "100%" }} alt="photo article" /> : null}
                    <IconButton
                      sx={{
                        zIndex: "33",
                        position: "absolute",
                        right: "0.5rem",
                        bottom: "0.5rem",
                        bgcolor: `${colors.blue}`,
                        "&:hover": { bgcolor: `${colors.blue_night}` },
                      }}
                      component="label"
                    >
                      <input
                        hidden
                        id="image"
                        name="image"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0] ? event.target.files[0] : null);
                          setImage(URL.createObjectURL(event.target.files[0]));
                        }}
                      />
                      <AddRoundedIcon htmlColor="white" />
                    </IconButton>
                  </Avatar>
                  {errors.image && touched.image && <MzErrorText>{errors.image}</MzErrorText>}
                </Box>
                <Box maxWidth="400px" display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Titre en francais :</label>
                  <Field type="input" name="nom_fr" as={TextField} label="Titre en francais" id="Nom" size="small" />
                  {errors.nom_fr && touched.nom_fr && <MzErrorText>{errors.nom_fr}</MzErrorText>}
                </Box>
                <Box maxWidth="400px" display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Titre en englais :</label>
                  <Field type="input" name="nom_eng" as={TextField} label="Titre en anglais" id="Nom" size="small" />
                  {errors.nom_eng && touched.nom_eng && <MzErrorText>{errors.nom_eng}</MzErrorText>}
                </Box>
                <Box maxWidth="400px" display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Tags en relation avec l'article : </label>
                  <Field
                    multiple={true}
                    name="tags"
                    component={Autocomplete}
                    options={options}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(s) => s}
                    onChange={(e, value) => {
                      setFieldValue("tags", value ? value : "");
                    }}
                    renderInput={(params) => <TextField sx={{ minWidth: "300px" }} {...params} name="autocomplete" label="Tags" />}
                    size="small"
                  />
                  {errors.tags && touched.tags && <MzErrorText>{errors.tags}</MzErrorText>}
                </Box>
                <Box display="flex" gap="0.5rem" alignItems="center">
                  <FormControlLabel control={<Checkbox checked={values.en_vedette} onChange={(e) => setFieldValue("en_vedette", e.target.checked)} />} label="En vedette" />
                  <FormControlLabel control={<Checkbox checked={values.Visible} onChange={(e) => setFieldValue("Visible", e.target.checked)} />} label="Visible" />
                </Box>
                <Box display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Contenu de l'article en francais :</label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={configFr}
                    tabIndex={1} // tabIndex of textarea
                    onChange={(newContent) => {
                      setFieldValue("text_fr", newContent);
                      setContent(newContent);
                    }} // preferred to use only this option to update the content for performance reasons
                  />
                  {errors.text_fr && <MzErrorText>{errors.text_fr}</MzErrorText>}
                </Box>
                <Box display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Contenu de l'article en englais :</label>
                  <JoditEditor
                    ref={editor}
                    value={contentENG}
                    config={configeEn}
                    tabIndex={1} // tabIndex of textarea
                    onChange={(newContent) => {
                      setFieldValue("text_eng", newContent);
                      setContentENG(newContent);
                    }} // preferred to use only this option to update the content for performance reasons
                  />
                  {errors.text_eng && <MzErrorText>{errors.text_eng}</MzErrorText>}
                </Box>
                <Box margin="2rem 0rem">
                  <Button type="submit" variant="primary">
                    Créer l'article
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </Box>
  );
}
export default CreateArticle;
