import React from "react";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import { colors } from "../../theme/Colors";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import digitup from "../../imgs/digitup-logo.svg";
import { useLogin } from "../../hooks/useLogin";

import MzErrorText from "../../components/ui/MzErrorText";
function Login() {
  const navigate = useNavigate();

  //initialize error, isLoading, and login function
  const { login, errorLogin, isLoading } = useLogin();

  const onSubmitLogin = async (values, errorLogin, isLoading) => {
    await login(formik.values.Email, formik.values.password);
  };

  const validationSchema = yup.object().shape({
    Email: yup.string().email("entrer un email valide").required("ce champ est obligatoire"),
    password: yup.string().required("ce champ est obligatoire"),
  });
  const formik = useFormik({
    initialValues: {
      Email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      onSubmitLogin();
    },
  });

  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: `${colors.blue_night}` }} display="flex" alignItems="center" justifyContent="center">
      <Box sx={{ boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.65)", padding: "2rem", background: "white", borderRadius: "5px", display: "flex", flexDirection: "column", gap: "0rem" }}>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
          <img src={digitup} alt="logo" />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box margin="1rem 2rem" display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <label>Email : </label>
              <TextField type="email" name="Email" value={formik.values.Email} onChange={formik.handleChange} placeholder="Email" sx={{ width: { xs: "180px", lg: "300px", md: "300px", sm: "250px" } }} />
              {formik.errors.Email && formik.touched.Email && <MzErrorText>{formik.errors.Email}</MzErrorText>}
            </Box>
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <label>Mot de passe :</label>
              <TextField placeholder="Mot de passe" name="password" value={formik.values.password} onChange={formik.handleChange} type="password" sx={{ width: { xs: "180px", lg: "300px", md: "300px", sm: "250px" } }} />
              {formik.errors.password && formik.touched.password && <MzErrorText>{formik.errors.password}</MzErrorText>}
            </Box>
          </Box>
          <Box onClick={() => navigate("/mot-de-passe-oublier")} marginRight="2rem" marginBottom="1rem" display="flex" sx={{ justifyContent: "flex-end" }}>
            <Typography sx={{ cursor: "pointer", textDecoration: "underline" }}>Mot de passe oublier</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button type="submit" disabled={isLoading} variant="primary">
              Se connecter
            </Button>
          </Box>
        </form>
        {errorLogin && (
          <Alert sx={{ marginTop: "1rem" }} severity="error">
            {errorLogin}
          </Alert>
        )}
      </Box>
    </Box>
  );
}

export default Login;
